import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Main from 'layouts/Main';
import Container from 'components/Container';
import FormalOfficialCeremony2023 from './FormalOfficialCeremony2023';

import {
  Features,
  Advantages,
} from './components';
import { Link, Typography } from '@mui/material';

const Events = () => {
  const theme = useTheme();
  return (
    <Main>
        <Container  >
          <FormalOfficialCeremony2023 />
        </Container>
        <Container >
          <Features />
        </Container>

    </Main>
  );
};

export default Events;
